<template>
  <div class="main-view">
    <div class="section shadow filter__wrap">
      <el-form ref="elFormDom" inline :model="table.params" :size="subUnitSize">
        <el-form-item label="用工单位" prop="company_name">
          <el-input clearable v-model.trim="table.params.company_name" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="员工姓名" prop="name">
          <el-input clearable v-model.trim="table.params.name" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="联系电话" prop="phone">
          <el-input clearable v-model.trim="table.params.phone" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch">查询
          </el-button>
          <el-button @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    
    <VTable
      has-pagionation
      title=''
      addText='添加'
      :field="field"
      :loading="table.loading"
      :data="table.data"
      :page='table.params.page'
      :pageSize='table.params.count'
      :total='table.total'
      :tree-props="{children: 'children', hasChildren: 'has_child'}"
      :hasPagionation="true"
      :default-expand-all="true"
      :showSelect="false"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    >
     
      <template v-slot:status="{row}">
        <!-- <span>{{toStr({1:'发起申请',2:'同意申请-待签字',3:'拒绝申请',4:'已签字',5:'拒绝签字',6:'办理离职完成'},row.status)}}</span> -->
        <span>{{toStr({1:'待确认',2:'待签字',3:'已拒绝',4:'待审核',5:'待签字',6:'已离职'},row.status)}}</span>
      </template>
      <template v-slot:dimission_type="{row}">
        <span>{{row.dimission_type == 1 ? '正常离职' : '自离'}}</span>
      </template>
      <template v-slot:action="{row}">
        <el-button v-if="row.sign_status == 4" type="text" @click="showDetail(row)">审核</el-button>
        <el-button v-else type="text" @click="showDetail(row)">查看</el-button>
      </template>
    </VTable>


    <!-- 离职申请 -->
    <el-dialog
      title="离职申请"
      :visible.sync="dialogVisible"
      width="500px"
      :before-close="handleClose">
      <el-form
        ref='elFormDom'
        class="detailForm"
        label-width="120px"
        :model="form.data"
        :rules='form.rules'
        :size="subUnitSize"
        >
        <el-form-item label="用工单位：">
          <div>{{curRow.company_name}}</div>
        </el-form-item>
        <el-form-item label="员工姓名：">
          <div>{{curRow.name}}</div>
        </el-form-item>
        <el-form-item label="联系电话：">
          <div>{{curRow.phone}}</div>
        </el-form-item>
        <el-form-item label="入职日期：">
          <div>{{curRow.entry_at}}</div>
        </el-form-item>
        <el-form-item label="离职原因：">
          <div>{{curRow.dimission_type == 1 ? '正常离职' : '自离' }}</div>
        </el-form-item>
        <el-form-item label="离职日期：">
          <div>{{curRow.dimission_at}}</div>
        </el-form-item>
        <el-form-item label="申请时间：">
          <div>{{curRow.created_at}}</div>
        </el-form-item>
        <el-form-item label="离职报告：">
          <el-image 
            style="width: 100px; height: 100px"
            :src="curRow.report_img" 
            :preview-src-list="[curRow.report_img]">
          </el-image>
        </el-form-item>
        <!-- 同意申请 -->
        <el-form-item label="处理结果：" v-if="curRow.status == 2">
          <div style="display:flex;justify-content: space-between;">
            <span>已同意</span>
            <span>{{curRow.check_at}}</span>
          </div>
        </el-form-item>
        <!-- 拒绝申请 -->
        <el-form-item label="处理结果：" v-if="curRow.status == 3">
          <div style="display:flex;justify-content: space-between;">
            <span>{{curRow.refuse_reason}}</span>
            <span>{{curRow.check_at}}</span>
          </div>
        </el-form-item>
        <!-- 已签字待审核 -->
        <template v-if="curRow.status >= 4">
          <el-form-item label="处理结果：">
            <div style="display:flex;justify-content: space-between;">
              <span>已同意</span>
              <span>{{curRow.check_at}}</span>
            </div>
          </el-form-item>
          <el-form-item label="员工签字：">
            <div style="display:flex;justify-content: space-between;">
              <el-image 
                fit="cover"
                style="width: 60px; height: 100px;transform: scale(1) rotate(-90deg);"
                :src="curRow.sign_img" 
                @click="showImg(curRow.sign_img)">
              </el-image>
              <span>{{curRow.sign_at}}</span>
            </div>
          </el-form-item>
        </template>
        <!-- 拒绝签名 -->
        <el-form-item label="拒绝签名：" v-if="curRow.status == 5">
          <div style="display:flex;justify-content: space-between;">
            <span>{{curRow.refuse_reason}}</span>
            <span>{{curRow.check_at}}</span>
          </div>
        </el-form-item>
        <!-- 已离职 -->
        <template v-if="curRow.status == 6">
          <el-form-item label="离职证明：">
            <div style="display:flex;justify-content: space-between;">
              <el-button plain size="mini"  @click="openPdf(curRow.img)" ><span style="color:#ff6600;">pdf</span> 点击查看</el-button>
              <span>{{curRow.over_at}}</span>
            </div>
          </el-form-item>
        </template>
      </el-form>
      <!-- 待确认、待审核时 （1:'待确认',2:'待签字',3:'已拒绝',4:'待审核',5:'待签字',6:'已离职'） -->
      <template #footer v-if="curRow.status == 1 || curRow.status == 4 ">
        <el-row type="flex" justify="center">
          <el-button type="danger" @click="openInner(1)">拒 绝</el-button>
          <el-button v-if="curRow.status == 1" type="primary" @click="handleAgree">确认</el-button>
          <el-button v-if="curRow.status == 4" type="primary" @click="openInner(2)">办理离职</el-button>
        </el-row>
      </template>

      <el-dialog
        width="500px"
        :title="curType == 1 ? '请输入拒绝理由' : '办理离职'"
        :visible.sync="innerVisible"
        append-to-body>
        <template v-if="curType == 1">
          <el-input
            type="textarea"
            placeholder="请输入"
            v-model="form.data.reason"
            maxlength="200"
            show-word-limit
            :autosize="{ minRows: 4, maxRows: 8}"
          ></el-input>
        </template>
        <template v-if="curType == 2">
          <div style="margin-bottom:30px;margin-left:20px;">
            <span style="width:90px;display: inline-block;">离职日期：</span>
            <span>
              <el-date-picker
                v-model="form.data.dimission_at"
                type="date"
                placeholder="选择日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd">
              </el-date-picker>
            </span>
          </div>
          <div style="margin-bottom:30px;margin-left:20px;">
            <span style="width:90px;display: inline-block;">离职月薪资：</span>
            <el-radio-group v-model="form.data.dimission_pay_type">
              <el-radio :label="1">正常发放</el-radio>
              <el-radio :label="2">无需发放</el-radio>
            </el-radio-group>
          </div>
        </template>
        <el-row type="flex" justify="center" style="margin-top:20px;">
          <el-button type="primary" @click="commit">确 定</el-button>
        </el-row>
      </el-dialog>

    </el-dialog>

    <!-- 图片预览 -->
    <el-image-viewer class="imageBox" v-if="showViewer" :on-close="closeViewer" :url-list="previewImages" />

  </div>
</template>

<script>
import VTable from '@/components/VTable';
import {getStorage} from '@/storage'
import { mixinTable } from '@/mixins/table.js'
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
export default {
  name: '',
  mixins:[mixinTable],
  components: {
    VTable,
    ElImageViewer
  },
  data() {
    return {
      icId: Number(getStorage('icId')), // 登录账号的身份id（0：总公司）
      token: getStorage('token'),
      subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
      field: [
        { name: "id", label: "ID", width:"60"},
        { name: "company_name", label: "用工单位", hidden: false},
        { name: "name", label: "姓名", hidden: false },
        { name: "phone", label: "联系电话", hidden: false},
        { name: "dimission_type", label: "离职原因", showTooltip:true, hidden: false},
        { name: "dimission_at", label: "离职日期", hidden: false},
        { name: "created_at", label: "申请日期", hidden: false},
        { name: "status", label: "状态", width: "140", hidden: false},
        { name: "action", label: "操作",width: "120", hidden: false }
      ],
      
      table: {
        loading: false,
        params: {
          name: '',
          phone: '',
          company_name: '',
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
      },

      curRow: {
        image:'https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg',
      },
      dialogVisible: false,
      form: {
        data: {
          id: '',
          reason: '', // 拒绝理由
          dimission_at: '', // 离职时间
          dimission_pay_type: 1, // 1：正常发放，2：无需发放
        },
        rules:{
          reason: [{ required: true, message:'必填项', trigger: 'change'}],
        }
      },
      showViewer: false,
      previewImages: [],
      innerVisible: false,
      textarea: '',
      radio: '',
      curType: 1,
    }
  },
  created() {
    this.getTable();
  },
  methods: {
    getTable() {
      this.$http.get('/admin/dimission/list', {params:this.table.params}).then(res => {
        if(res.code === 1) {
          this.table.data = res.data.list;
          this.table.total = res.data.total;
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 查看
    showDetail(row) {
      this.dialogVisible = true;
      this.$http.get('/admin/dimission/detail', {params:{id:row.id}}).then(res => {
        if(res.code == 1) {
          this.curRow = res.data;
          this.form.data.dimission_at = res.data.dimission_at;
        }
      })
    },
    // 确认
    handleAgree() {
      this.$confirm('请确认离职操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.aduitFun(1);
      }).catch(() => {});
    },
    // 审核离职
    aduitFun(type) {
      let _params = {
        id: this.curRow.id,
        audit:type, // 审核：1-确认；2-拒绝
        reason: this.form.data.reason, // 拒绝原因
      }
      if(!this.form.data.reason && type == 2) {
        return this.$message.warning('请输入拒绝理由')
      }
      this.$http.post('/admin/dimission/audit', _params).then(res => {
        if(res.code == 1) {
          this.$message.success('操作成功！')
          this.innerVisible = false;
          this.dialogVisible = false;
          this.form.data.reason = '';
          this.getTable()
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 签名审核
    confirmFun(type) {
      let _params = {
        id: this.curRow.id,
        audit: type, //审核：1-确认；2-拒绝；
        dimission_at: this.form.data.dimission_at,
        dimission_pay_type: this.form.data.dimission_pay_type,
        reason: this.form.data.reason,
      }
      if(!this.form.data.reason && type == 2) {
        return this.$message.warning('请输入拒绝理由')
      }
      this.$http.post('/admin/dimission/confirm', _params).then(res => {
        if(res.code == 1) {
          this.$message.success('操作成功！')
          this.innerVisible = false;
          this.dialogVisible = false;
          this.form.data.reason = '';
          this.getTable();
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    commit() {
      // curType, 1:拒绝，2：办理离职
      if(this.curType == 1 && this.curRow.status == 1) {
        this.aduitFun(2) // 拒绝离职申请
      } else if(this.curType == 1 && this.curRow.status == 4) {
        this.confirmFun(2) // 拒绝签名申请
      } else if(this.curType == 2)  {
        this.confirmFun(1) // 同意签名申请（办理离职）
      }
    },
    
    handleClose() {
      this.dialogVisible = false;
      this.$refs.elFormDom.resetFields();
    },
    // 查看图片
    showImg(image) {
      if (image) {
        this.showViewer = true;
        this.previewImages = [image];
      } else {
        this.$message({
          type: "error",
          message: "图片不存在!",
        });
      }
    },
    // 关闭图片
    closeViewer() {
      this.showViewer = false;
      this.previewImages = [];
    },
    // 拒绝、办理离职
    openInner(type) {
      this.curType = type;
      this.innerVisible = true;

    },
    // 查看离职证明
    openPdf(url) {
      if(!!url) {
        window.open(url)
      } else {
        this.$message.warning('暂无离职证明')
      }
    }
  }
}
</script>
<style >
.imageBox {
  z-index: 9999 !important;
}
.imageBox .el-image-viewer__canvas {
  transform: rotate(-90deg);
}
</style>